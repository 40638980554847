import styled from "@emotion/styled";
import { Alert } from "antd";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import IconText from "../../../../../components/IconText";
import Paragraph from "../../../../../components/Paragraph";
import TagsInput from "../../../../../features/TagsInput";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import { IconTypeE } from "../../../../../ui/icons/Icon";
import { CampaignT } from "../../../types";

const DisabledMessage = styled(Paragraph)({
  color: theme.color.textGreyColor,
  marginLeft: rem(25),
});

type AddNewModalT = {
  instagramHandles: string[];
  tiktokHandles: string[];
  setInstagramHandles: Dispatch<SetStateAction<string[]>>;
  setTiktokHandles: Dispatch<SetStateAction<string[]>>;
  campaign: CampaignT;
};

const AddNewModal: FC<AddNewModalT> = ({
  instagramHandles,
  tiktokHandles,
  setInstagramHandles,
  setTiktokHandles,
  campaign,
}) => {
  const instagramDisabled = campaign.instagram_mentions.length === 0;
  const tiktokDisabled =
    campaign.tiktok_mentions.length === 0 && campaign.hashtags.length === 0;

  const { t } = useTranslate("brands.campaign.partnerships.form");

  return (
    <FlexBox
      flexDirection="column"
      alignItems="stretch"
      customWidth={CustomWidthE.full}
      gap={rem(24)}
    >
      <FlexBox alignItems="flex-start" flexDirection="column" gap={rem(8)}>
        <IconText
          icon={IconTypeE.socInstagram}
          paragraph={t("networks.instagram")}
        />
        <TagsInput
          disabled={instagramDisabled}
          placeholder={t("handles.placeholder")}
          addonBefore={"@"}
          setValues={setInstagramHandles}
          values={instagramHandles}
        />
        {instagramDisabled && (
          <DisabledMessage paragraph={t("disabled")} paragraphSize="micro" />
        )}
      </FlexBox>
      <FlexBox alignItems="flex-start" flexDirection="column" gap={rem(8)}>
        <IconText icon={IconTypeE.socTiktok} paragraph={t("networks.tiktok")} />
        <TagsInput
          disabled={tiktokDisabled}
          placeholder={t("handles.placeholder")}
          addonBefore={"@"}
          setValues={setTiktokHandles}
          values={tiktokHandles}
        />
        {tiktokDisabled && (
          <DisabledMessage paragraph={t("disabled")} paragraphSize="micro" />
        )}
      </FlexBox>
      <Alert message={t("handles.hint")} type="info" showIcon />
    </FlexBox>
  );
};

export default AddNewModal;
