import styled from "@emotion/styled";
import { Button, Checkbox, Divider, Form } from "antd";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../components/FlexBox";
import LoaderComponent from "../../../../components/Loader";
import Paragraph from "../../../../components/Paragraph";
import { designToken } from "../../../../helpers/antDesign";
import { putSpacesToThousand } from "../../../../helpers/formaters";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import { ChargebeeLogo } from "../../../../ui/icons/CustomIcons";
import { SubscriptionPriceEstimateT, SubscriptionProposalT } from "../../types";
import { CheckoutFieldTypeT } from "./CheckoutForm";
import { PlanPriceStateT } from "./WizardContainer";

const PoweredByWrapper = styled(FlexBox)({
  marginTop: rem(36),
});

const TermsWrapper = styled.div({
  a: {
    color: designToken.colorPrimary,

    ":hover": {
      textDecoration: "underline",
    },
  },
});

const StyledDivider = styled(Divider)({
  margin: 0,
});

type PriceSummaryT = {
  priceEstimate: SubscriptionPriceEstimateT | undefined;
  subscriptionProposal: SubscriptionProposalT | undefined;
  planPriceState: PlanPriceStateT | undefined;
  isFetching: boolean;
  paymentType: string;
};

const PriceSummary: FC<PriceSummaryT> = ({
  priceEstimate,
  subscriptionProposal,
  planPriceState,
  isFetching,
  paymentType,
}) => {
  const planName = planPriceState
    ? planPriceState.plan.external_name
    : subscriptionProposal?.plan;
  const amount = priceEstimate?.price_without_vat.amount;
  const currency = priceEstimate?.price_without_vat.currency;
  const period = planPriceState
    ? planPriceState.price.period_unit
    : subscriptionProposal?.billing_period_unit;
  const vatPercentage = priceEstimate?.vat_rate;
  const vatAmount = priceEstimate?.vat.amount;
  const amountWithVat = priceEstimate?.price_with_vat.amount;

  const { t } = useTranslate("brands.wizard.checkout");

  const formattedAmountWithVat =
    amountWithVat &&
    currency &&
    `${putSpacesToThousand(amountWithVat.toFixed(2))} ${t(
      `currencies.${currency.toLowerCase()}`,
    )}`;

  if (isFetching || !priceEstimate) {
    return <LoaderComponent />;
  }

  return (
    <>
      <FlexBox
        flexDirection="column"
        customWidth="100%"
        alignItems="flex-start"
        gap={rem(24)}
      >
        <Paragraph paragraph={t("summary")} fontWeight={600} />
        <FlexBox
          flexDirection="column"
          alignItems="flex-start"
          customWidth="100%"
        >
          <FlexBox customWidth="100%" justifyContent="space-between">
            <Paragraph
              paragraph={`${t("plan", {
                title: t(`form.plans.${planName?.toLowerCase()}`),
              })} - ${t(`billing.${period}`)}`}
              paragraphSize="small"
            />
            <Paragraph
              paragraph={
                amount && currency
                  ? `${putSpacesToThousand(amount.toFixed(2))} ${t(
                      `currencies.${currency.toLowerCase()}`,
                    )}`
                  : ""
              }
              paragraphSize="small"
              fontWeight={600}
            />
          </FlexBox>
          <FlexBox customWidth="100%" justifyContent="space-between">
            <Paragraph
              paragraph={`${t("form.vat_amount")} (${vatPercentage}%)`}
              paragraphSize="small"
            />
            <Paragraph
              paragraph={
                typeof vatAmount == "number" && currency
                  ? `${putSpacesToThousand(vatAmount.toFixed(2))} ${t(
                      `currencies.${currency.toLowerCase()}`,
                    )}`
                  : ""
              }
              paragraphSize="small"
              fontWeight={600}
            />
          </FlexBox>
        </FlexBox>
        <StyledDivider />
        <FlexBox customWidth="100%" justifyContent="space-between">
          <Paragraph paragraph={t("total")} fontWeight={600} />
          <Paragraph
            paragraph={formattedAmountWithVat || ""}
            fontWeight={600}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox
        flexDirection="column"
        customWidth="100%"
        alignItems="flex-start"
      >
        <Form.Item<CheckoutFieldTypeT>
          style={{ marginBottom: rem(16) }}
          name="tac"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: t("form.errors.base"),
            },
          ]}
        >
          <Checkbox>
            <TermsWrapper>
              <Paragraph paragraph={t("form.tac")} paragraphSize="small" />
            </TermsWrapper>
          </Checkbox>
        </Form.Item>
        <Form.Item<CheckoutFieldTypeT>
          style={{ width: "100%", marginBottom: rem(16) }}
          labelCol={{ span: 24 }}
        >
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            {t(`form.save_${paymentType}`, {
              price: formattedAmountWithVat,
            })}
          </Button>
        </Form.Item>
        <Paragraph
          paragraph={t(
            paymentType === "invoice"
              ? "form.submit_description"
              : "form.coupon_info",
          )}
          color="grey"
          paragraphSize="small"
        />
        <PoweredByWrapper gap={rem(12)} customWidth="100%">
          <Paragraph
            paragraph="Powered by"
            paragraphSize="small"
            color="grey"
          />
          <ChargebeeLogo />
        </PoweredByWrapper>
      </FlexBox>
    </>
  );
};

export default PriceSummary;
